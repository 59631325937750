import { Component } from '@angular/core';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent {

  constructor(
    private cartService: CartService,
    private router: Router
  ) {}

  add(sku: string, qty: number = 1) {
    this.cartService.add(sku, qty);
    this.router.navigate(['/checkout']);
  }
}
