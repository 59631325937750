<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">

    <a routerLink="/pay" class="float-right" id="back-to-checkout">Back to payment</a>

    <h1>Terms &amp; Conditions</h1>

    <p>Please be aware that:</p>
    <ol>
      <li>Owing to the COVID-19 pandemic there may be a delay in dispatch.</li>
      <li>You may cancel your order at any time before dispatch by sending a cancellation request to
        <a href="mailto:enquiries@maxdmyz.uk">enquiries@maxdmyz.uk</a>. We will refund your payment in full.
      </li>
      <li>After the goods have been dispatched, you may only cancel your order on condition that the goods are sent back
        to us unopened and at your expense. We will refund your payment in full.
      </li>
      <li>Once goods are opened, cancellations will not be accepted.</li>
    </ol>

  </div>
</div>
