<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">
    <h4>Order Summary</h4>

    <div class="list-group">

      <span class="list-group-item flex-column list-group-item-dark">
        <div class="d-flex w-100 justify-content-between">
          <p class="lead">Subtotal ({{ calcs.count }} items): <strong>£{{ calcs.subtotal }}</strong></p>
          <p class="lead">
            <i class="fas fa-check text-success" *ngIf="calcs.count"></i>
            <i class="fas fa-times text-danger" *ngIf="calcs.count < 1"></i>
          </p>
        </div>
        <ng-container *ngIf="items.length === 0">
            <div class="alert alert-warning" role="alert">
              <i class="fas fa-exclamation-triangle"></i>
              Please add items to your basket
            </div>
        </ng-container>
      </span>

      <span class="list-group-item flex-column list-group-item-dark">
        <div class="d-flex w-100 justify-content-between">
          <p class="lead">Shipping Information</p>
          <p class="lead">
            <i class="fas fa-check text-success" *ngIf="address.country"></i>
            <i class="fas fa-times text-danger" *ngIf="! address.country"></i>
          </p>
        </div>
        <p>Postage and packaging: £{{ calcs.total - calcs.subtotal }}</p>
        <ng-container *ngIf="address.country">
        <p class="mb-1">The order will be sent by post to</p>
        <address>
          <strong>{{ customer.name }}</strong><br>
          <span>{{ address.line1}}</span><br>
          <span *ngIf="address.line2">{{ address.line2}}<br></span>
          <span>{{ address.city}}</span><br>
          <span>{{ address.country}}</span><br>
          <span>{{ address.postal_code}}</span><br>
          <span *ngIf="address.state">{{ address.state}}<br></span>
          <span *ngIf="customer.phone"><abbr title="Phone">P:</abbr> {{ customer.phone }}</span>
        </address>
        </ng-container>

        <ng-container *ngIf=" ! address.country">
          <div class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i>
            Shipping information missing.
            <a routerLink="/shipping">Add</a>
          </div>
        </ng-container>
      </span>

      <span class="list-group-item flex-column list-group-item-dark">

        <div class="d-flex w-100 justify-content-between">
          <p class="lead">Email confirmation</p>
          <p class="lead">
            <i class="fas fa-check text-success" *ngIf="customer.email"></i>
            <i class="fas fa-times text-danger" *ngIf="! customer.email"></i>
          </p>
        </div>

        <ng-container *ngIf=" ! customer.email">
          <div class="alert alert-warning" role="alert">
            <i class="fas fa-exclamation-triangle"></i>
            Email address is missing.
          </div>
        </ng-container>
        <p class="mb-1" *ngIf="customer.email">The email confirmation will be sent to email address: <strong>{{ customer.email }}</strong></p>
      </span>

    </div>

    <div *ngIf="route == '/checkout'">
      <p class="lead text-center m-5">Total £{{ calcs.total }}</p>

      <div id="payment-request-button">
        <button class="btn btn-lg btn-outline-warning btn-block" routerLink="/pay" *ngIf="(customer.email && address.country && calcs.count)">Pay £{{ calcs.total }} now</button>
      </div>
    </div>


  </div>
</div>
