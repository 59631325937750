<div class="card card-dark">
  <div class="card-header">
    <h1>Listen Here</h1>
  </div>
  <div class="card-body">
    <h5 class="card-title">Another Dead Son</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/another_dead_son.mp3"/>
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Anatomy of Power</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/anatomy_of_power.mp3" />
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">My State</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/my_state.webm" type="audio/ogg"/>
      <source src="/assets/sounds/my_state.mp3"/>
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Slip Away</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/slip_away.mp3" />
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Play Dead</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/play_dead.mp3" />
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Culture of One</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/culture_of_one.webm" type="audio/ogg"/>
      <source src="/assets/sounds/culture_of_one.mp3" />
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Death to the Nations</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/death_to_the_nations.mp3" />
    </audio>
  </div>
</div>
<div class="card card-dark">
  <div class="card-body">
    <h5 class="card-title">Hecatomb</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/sounds/hecatomb.webm" type="audio/ogg"/>
      <source src="/assets/sounds/hecatomb.mp3"/>
    </audio>
  </div>
</div>

<br>

<div class="card card-dark">
  <img src="/assets/img/no-keys.jpg" class="card-img-top" alt="Maxdmyz">
  <div class="card-body">
    <p class="card-text"><strong>Genre:</strong> Extreme Alternative Metal</p>
    <p class="card-text"><strong>For fans of:</strong> Rammstein | Faith No More | Devin Townsend | Fear Factory</p>
  </div>
</div>

<br>

<div class="card card-dark">
  <img src="/assets/img/upstairs.jpg" class="card-img-top" alt="Maxdmyz">
  <div class="card-body">
    <h5 class="card-title">Line-up</h5>
    <ul class="list-unstyled">
      <li><strong>Pietro Valente</strong> - Vocals/FX</li>
      <li><strong>Jamison Nes</strong> - Bass</li>
      <li><strong>Filon</strong> - Guitars</li>
      <li><strong>Jamie Tatnell</strong> - Drums/FX/Programming</li>
      <li><strong>Markus Pesonen</strong> - Keys/Programming</li>
    </ul>
  </div>
</div>


