import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Item } from './common/model/Item';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Address } from './common/model/Address';
import { Customer } from './common/model/Customer';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private emitChange = new Subject<any>();
  changeEmitted$ = this.emitChange.asObservable();

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  setEmail(payload: { emailAddress: string, subscribe?: boolean}) {

    let customer: Customer = this.storage.get('customer') || {};

    customer.email = payload.emailAddress;
    customer.subscribe = payload.subscribe;

    this.storage.set('customer', customer);
    return this.emitChange.next(customer);
  }
}
