<br> <br>
<div class="card card-dark">
  <div class="card-header">
    <h1>Listen Here</h1>
    <p>These are short samples from our new album.</p>
  </div>
  <div class="card-body">
    <h5 class="card-title">My State</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/samples/my_state.mp3"/>
    </audio>
  </div>
  <div class="card-body">
    <h5 class="card-title">Culture of One</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/samples/culture_of_one.mp3"/>
    </audio>
  </div>
  <div class="card-body">
    <h5 class="card-title">Slip Away</h5>
    <audio class="mejs__player w-100">
      <source src="/assets/samples/slip_away.mp3"/>
    </audio>
  </div>
</div>
