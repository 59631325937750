<div class="card card-dark">
  <img src="/assets/images/anatomy.jpg" class="card-img-top" alt="Anatomy of Power">
  <div class="card-body">
    <h5 class="card-title">Anatomy of Power</h5>
    <p class="card-text">New album available everywhere.</p>
  </div>
</div>

<div class="list-group">
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/spotify.svg" alt="Spotify" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://open.spotify.com/album/1zLdyuD97QcAFbdlo2R3FU"
       (click)="getOutboundLink('https://open.spotify.com/album/1zLdyuD97QcAFbdlo2R3FU')" target="_blank">Listen</a>
  </li>
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/apple.svg" alt="Apple Music" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://music.apple.com/gb/album/anatomy-of-power/1500123612"
       (click)="getOutboundLink('https://music.apple.com/gb/album/anatomy-of-power/1500123612')" target="_blank">Listen</a>
  </li>
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/deezer.svg" alt="Dezeer" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://www.deezer.com/en/album/133133892"
       (click)="getOutboundLink('https://www.deezer.com/en/album/133133892')" target="_blank">Listen</a>
  </li>
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/amazon.svg" alt="Amazon Music" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://music.amazon.com/albums/B08547W74H"
       (click)="getOutboundLink('https://music.amazon.com/albums/B08547W74H')" target="_blank">Listen</a>
  </li>
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/youtube_music.svg" alt="Youtube Music" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://music.youtube.com/playlist?list=OLAK5uy_kVQrPAGbgdE9yabBh1lLvGl8IxO_R4Ayw"
       (click)="getOutboundLink('https://music.youtube.com/playlist?list=OLAK5uy_kVQrPAGbgdE9yabBh1lLvGl8IxO_R4Ayw')" target="_blank">Listen</a>
  </li>
  <li class="list-group-item list-group-item-dark list-group-item-action">
    <img src="/assets/img/youtube.svg" alt="Youtube" class="img-fluid" style="width: 6rem; margin: -2rem 0;">
    <a class="btn btn-outline-warning float-right"
       href="https://www.youtube.com/playlist?list=PLOxVu52xNk7cmYeNCSk4xiLPuEPpoVjvp"
       (click)="getOutboundLink('https://www.youtube.com/playlist?list=PLOxVu52xNk7cmYeNCSk4xiLPuEPpoVjvp')">Listen</a>
  </li>
</div>
