<div class="card-group">

  <div class="card card-transparent mt-md-5">

    <div class="card-body">
      <p class="small text-center d-block">We're Maxdmyz - metal band from London UK</p>
      <h1 class="card-title text-center">Anatomy of Power</h1>
      <h2 class="text-center">OUR BRAND NEW ALBUM IS OUT NOW</h2>
      <p class="text-center d-block">Available at all usual outlets</p>
      <p class="text-center d-block">Direct links below</p>
    </div>

  </div>

</div>

<div class="d-flex flex-wrap justify-content-center">

  <div style="max-width: 10rem;">
    <a href="https://open.spotify.com/album/1zLdyuD97QcAFbdlo2R3FU"
       (click)="getOutboundLink('https://open.spotify.com/album/1zLdyuD97QcAFbdlo2R3FU')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/spotify.svg" alt="Spotify" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://music.apple.com/gb/album/anatomy-of-power/1500123612"
       (click)="getOutboundLink('https://music.apple.com/gb/album/anatomy-of-power/1500123612')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/apple.svg" alt="Apple Music" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://music.youtube.com/playlist?list=OLAK5uy_kVQrPAGbgdE9yabBh1lLvGl8IxO_R4Ayw"
       (click)="getOutboundLink('https://music.youtube.com/playlist?list=OLAK5uy_kVQrPAGbgdE9yabBh1lLvGl8IxO_R4Ayw')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/youtube_music.svg" alt="Youtube Music" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://www.youtube.com/playlist?list=PLOxVu52xNk7cmYeNCSk4xiLPuEPpoVjvp"
       (click)="getOutboundLink('https://www.youtube.com/playlist?list=PLOxVu52xNk7cmYeNCSk4xiLPuEPpoVjvp')"
       target="_blank" class="text-center hide-text">
      <img src="/assets/img/youtube.svg" alt="YouTube" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://music.amazon.com/albums/B08547W74H"
       (click)="getOutboundLink('https://music.amazon.com/albums/B08547W74H')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/amazon.svg" alt="Amazon Music" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://tidal.com/browse/album/132134109"
       (click)="getOutboundLink('https://tidal.com/browse/album/132134109')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/tidal.svg" alt="Tidal" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://www.deezer.com/en/album/133133892"
       (click)="getOutboundLink('https://www.deezer.com/en/album/133133892')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/deezer.svg" alt="Deezer" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a href="https://us.napster.com/artist/maxdmyz/album/anatomy-of-power"
       (click)="getOutboundLink('https://us.napster.com/artist/maxdmyz/album/anatomy-of-power')"
       target="_blank" class="text-center hide-text">
      <img src="/assets/img/napster.svg" alt="Napster" class="img-fluid">
    </a>

  </div>

  <div style="max-width: 10rem;">
    <a href="https://www.qobuz.com/gb-en/album/anatomy-of-power-maxdmyz/x5f3oulrmafwb"
       (click)="getOutboundLink('https://www.qobuz.com/gb-en/album/anatomy-of-power-maxdmyz/x5f3oulrmafwb')"
       target="_blank" class="text-center hide-text">
      <img src="/assets/img/qobuz.svg" alt="Qobuz" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;" class="pt-3">
    <a href="https://www.akazoo.com/album/29101762/anatomy-of-power"
       (click)="getOutboundLink('https://www.akazoo.com/album/29101762/anatomy-of-power')" target="_blank"
       class="text-center hide-text">
      <img src="/assets/img/akazoo.png" alt="Akazoo" class="py-5 px-1 img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a (click)="add('cd')" class="cursor-pointer">
      <img src="/assets/img/cd.svg" alt="Order CD" class="img-fluid">
    </a>
  </div>

  <div style="max-width: 10rem;">
    <a (click)="add('vinyl')" class="cursor-pointer">
      <img src="/assets/img/vinyl.svg" alt="Order CD" class="img-fluid">
    </a>
  </div>


</div>
