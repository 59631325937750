import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Item } from '../common/model/Item';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CartService } from '../cart.service';
import { Address } from '../common/model/Address';
import { Customer } from '../common/model/Customer';
import { Intent } from '../common/model/Intent';
import { Order } from '../common/model/Order';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { UserService } from '../user.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {

  public items: Array<Item>;
  public calcs: { subtotal: number, total: number, count: number };
  public address: Address;
  public customer: Customer = new Customer();

  @Input() route: string;

  private subscriptions: Array<Subscription> = [];

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cartService: CartService,
    private userService: UserService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.items = this.storage.get('items') || [];
    this.address = this.storage.get('address') || new Address();
    this.customer = this.storage.get('customer') || new Customer();

    this.calcs = this.cartService.calculateTotal(this.items);

    this.subscriptions.push(this.cartService.changeEmitted$.subscribe((items: Array<Item>) => {
      this.items = items;
      this.calcs = this.cartService.calculateTotal(items);
    }));

    this.subscriptions.push(this.userService.changeEmitted$.subscribe((customer: Customer) => {
      this.customer = customer;
    }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

}
