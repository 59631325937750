<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">

    <a routerLink="/checkout" class="float-right" id="back-to-checkout">Change your order</a>

    <h1>Payment</h1>

    <div id="payment-request-button"></div>
    <div class="alert alert-danger" role="alert" id="pay-errors"></div>

    <form id="payment-form">
      <div class="form-group">
        <label for="card-element">Credit or debit card number</label>
        <div class="input-group">
          <div id="card-element" class="form-control"></div>
          <div id="card-errors" class="invalid-feedback"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="expiry-element">Card expiry date</label>
            <div class="input-group">
              <div id="expiry-element" class="form-control"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="cvc-element">CVC code</label>
            <div class="input-group">
              <div id="cvc-element" class="form-control"></div>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-dark btn-block btn-lg">Pay £{{ total }}</button>

    </form>

    <div id="terms">
      <br><br>
      <p>By paying for this preorder, you accept our <a routerLink="/terms">Terms and Conditions</a></p>
    </div>

    <div id="success">
      <div class="alert alert-outline-success" role="alert">
        <p class="lead">Payment Successful!</p>
        <p>An email confirmation will be sent shortly.</p>
      </div>

      <p class="lead">Maxdmyz would like to thank you for your support.</p>
      <p>Please note that owing to the COVID-19 pandemic, dispatch may be delayed.</p>

      <p>And to fill those idle moments as you wait with bated breathe for your brand spanking new copy of Anatomy of
        Power, feel free to check out our social media sites where you'll find videos, pics and more music by
        Maxdmyz.</p>


      <div class="card-group">

        <div class="card card-transparent text-white">
          <a href="https://www.facebook.com/maxdmyz/" target="_blank" class="text-center hide-text">
            <i class="fab fa-facebook display-3 p-5" style="color: #3b5998"></i>
          </a>
        </div>

        <div class="card card-transparent text-white">
          <a href="https://www.instagram.com/maxdmyz/" target="_blank" class="text-center hide-text">
            <i class="fab fa-instagram display-3 p-5" style="color: #ffdc80"></i>
          </a>
        </div>

        <div class="card card-transparent text-white">
          <a href="https://twitter.com/maxdmyz/" target="_blank" class="text-center hide-text">
            <i class="fab fa-twitter display-2 p-5" style="color: #3b5998"></i>
          </a>
        </div>

      </div>

      <div class="card-group">

        <div class="card card-transparent text-white">
          <a href="https://open.spotify.com/artist/6BoU8yNT62U2aVt8oH2JWK" target="_blank" class="text-center hide-text">
            <i class="fab fa-spotify display-3 p-5" style="color: #1db954"></i>
          </a>
        </div>

        <div class="card card-transparent text-white">
          <a href="https://music.apple.com/gb/artist/maxdmyz/309509851" target="_blank" class="text-center hide-text">
            <i class="fab fa-itunes display-3 p-5" style="color: #ffffff"></i>
          </a>
        </div>

        <div class="card card-transparent text-white">
          <a href="https://soundcloud.com/maxdmyz/" target="_blank" class="text-center hide-text">
            <i class="fab fa-soundcloud display-3 p-5" style="color: #ff8800"></i>
          </a>
        </div>

      </div>
    </div>



  </div>
</div>
