<div id="accordion">
  <div class="card card-dark">
    <div class="card-header" id="another_dead_son_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#another_dead_son" aria-expanded="true" aria-controls="another_dead_son">
          Another Dead Son
        </a>
      </h5>
    </div>

    <div id="another_dead_son" class="collapse show" aria-labelledby="another_dead_son_header" data-parent="#accordion">
      <div class="card-body">

        <p>Behind your eyes, I know you idolise, romanticise, another dead son<br>
          Amid the sighs, although I sympathise, you know the truth defies another dead son<br>
          A martyr, a hero, a man from the sky, how could you leave us, just hang there and die<br>
          We wanted the myth, the legend, the lie<br>
          We don’t know you, but owned you<br>
          A god in disguise</p>

        <p>Praised to the skies, raised to the heavens<br>
          Idealised, deified</p>

        <p>In your eyes, I see you idolise, romanticise, another dead son<br>
          Praised to the skies, raised to the heavens, idealised, deified</p>

        <p>Choked up, sucked down<br>
          In tears to drown<br>
          My heart cannot take<br>
          It’s all too fake<br>
          Cracked up inside<br>
          My wounded pride<br>
          Can’t take the strain<br>
          That came with my fame<br>
          You know I tried, my widowed bride<br>
          No hate, no blame<br>
          Suffocated in my shame</p>

        <p>Praised to the skies, raised to the heavens, idealised, deified<br>
          Another dead son x 24</p>
      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="anatomy_of_power_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#anatomy_of_power" aria-expanded="true" aria-controls="anatomy_of_power">
          Anatomy of Power
        </a>
      </h5>
    </div>

    <div id="anatomy_of_power" class="collapse" aria-labelledby="anatomy_of_power_header" data-parent="#accordion">
      <div class="card-body">
        <p>Born without a face<br>
          Out of mind, without a trace</p>

        <p>Instinct, on line, lip-synched, in time<br>
          Stitched up, locked down, cast off, spun round</p>

        <p>Some make you kill, some make you scream, some make you wish that you’d just never been<br>
          Some make you suffer, some make you cry, some barely care if you live or you die<br>
          Yeah, you and I</p>

        <p>Born with no-one there<br>
          Nothing to show, no-one to care<br>
          To love or share</p>

        <p>Soldiers in line, ascend, decline<br>
          In step, unsound, don’t you turn down</p>

        <p>Soldiers in line, descend, incline<br>
          In step, unsound, don’t dare look down<br>
          Don’t turn around, or make a sound</p>

        <p>Attention<br>
          Make war on war</p>

        <p>Someone to kill<br>
          An act of pure will<br>
          Messed up and fucked<br>
          You’ll be suffering still<br>
          Someone to scream inside the machine<br>
          Someone to choose, win or lose<br>
          Someone to laugh, to despise<br>
          Someone to ridicule, victimise<br>
          You and I </p>

        <p>Make war on war x 4</p>
      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="my_state_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#my_state" aria-expanded="true" aria-controls="my_state">
          My State
        </a>
      </h5>
    </div>

    <div id="my_state" class="collapse" aria-labelledby="my_state_header" data-parent="#accordion">
      <div class="card-body">
        <p>Compelled to seek can you find, a creation beyond time <br>
          Made to seek could you find, a creation beyond mind </p>

        <p>My state, there is no fate, yet no escape <br>
          No land of dreams, just these extremes<br>
          All as it seems</p>

        <p>Made to seek could you find, a creation beyond mine  </p>

        <p>Keep me from the stars, what is, what was<br>
          And what will be, eternally <br>
          There is no fate <br>
          This is my state  </p>

        <p>Ease, a breeze, the seas lap gently at the edges of my mind <br>
          Rest, accept, forget, the refuge of my kind<br>
          Feel, unreal, reveal, the echoes at the limits of my mind<br>
          Me to thee, the risen life is now, don’t lay it down x 3</p>

      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="slip_away_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#slip_away" aria-expanded="true" aria-controls="slip_away">
          Slip Away
        </a>
      </h5>
    </div>

    <div id="slip_away" class="collapse" aria-labelledby="slip_away_header" data-parent="#accordion">
      <div class="card-body">
        <p>As it ends, so it begins, slipping and slipping inside of me <br>
          An endless scream, a bitter scene of jealousy and rage, streaming down my page</p>

        <p>Conceivers, marauders, receivers, defrauders, close minds, you’re living your life in a cage<br>
          Leaders, emotion deceivers, you’re all blind, so I’m sweeping the blood off the stage (blood lines, soul crimes)<br>
          Turning my heels on your hate <br>
          Holding my line at the gate</p>

        <p>These wars are the bleeders, they’re feeding the caesars <br>
          Trading your soul as you’re losing control<br>
          Let it go now, reach for the peace as you beg for release <br>
          Let it go just let it all slip away x 2</p>

        <p>As it ends, so it begins, creeping and creeping inside of me, <br>
          An endless kill, a bitter pill of jealousy and rage, streaming down my page</p>

        <p>Conceivers, marauders, receivers, defrauders, close minds<br>
          You’re living your life in a haze<br>
          Leaders, emotion deceivers, you’re all blind<br>
          Chasing your tail in a maze (blood lines, soul crimes) <br>
          Your hatreds are sealing your fate <br>
          Burning your boats at the gate</p>

        <p>These wars are the breeders, they’re feeding the caesars <br>
          Trading your soul as you’re losing control <br>
          Let it go now, reach for the peace as you beg for release<br>
          Let it go, just let it all slip away</p>

        <p>You are blind, you’re all blind x 4</p>

        <p>Slip, slip, slip x 3 <br>
          Why don’t you all slip away x 6<br>
          Slip slip slip x 3</p>

      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="play_dead_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#play_dead" aria-expanded="true" aria-controls="play_dead">
          Play Dead
        </a>
      </h5>
    </div>

    <div id="play_dead" class="collapse" aria-labelledby="play_dead_header" data-parent="#accordion">
      <div class="card-body">
        <p>I got this feeling, I hold inside<br>
          A sense of dread and evil <br>
          fear, anger, hurt and pride<br>
          Of violence no redemption <br>
          It’s a relentless tide <br>
          A flood of stinking faeces<br>
          Makes me wanna sink and hide</p>

        <p>Keep your head down, kneeling</p>

        <p>So a life that’s filled with doubt<br>
          A thing I could do without <br>
          Try to say my prayer<br>
          Find some comfort there<br>
          Just crawling in the shadows <br>
          Seek refuge in the shallows <br>
          Resistance, to survive <br>
          Instinct’s to stay alive</p>

        <p>Protect myself, my health, my wealth <br>
          Avoid, the void, destroyed, cut down, <br>
          Without a sound,<br>
          I’ve gone to ground, to defend my world<br>
          I play dead x 7</p>

        <p>So a life that’s filled with doubt <br>
          A thing I could do without <br>
          Try to say my prayer<br>
          Find some comfort there <br>
          Just crawling in the shadows<br>
          Seek refuge in the shallows<br>
          Resistance, to survive<br>
          Instinct’s to stay alive</p>

        <p>Protect myself, my wealth, my health <br>
          Avoid, the void, destroyed, cut down, <br>
          Without a sound,<br>
          I’ve gone to ground, to defend my world<br>
          I play dead x 8</p>

        <p>I’ve gone to ground, to defend my world <br>
          I play dead x 9 <br>
          Stay dead, stay dead</p>

      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="culture_of_one_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#culture_of_one" aria-expanded="true" aria-controls="culture_of_one">
          Culture of One
        </a>
      </h5>
    </div>

    <div id="culture_of_one" class="collapse" aria-labelledby="culture_of_one_header" data-parent="#accordion">
      <div class="card-body">
        <p>Well you know it's come to this, a hopeless endless deep abyss<br>
          Choking back on poison mist, it’s the end of time <br>
          Through my jaundiced dreamy eyes, stalk predation in disguise<br>
          Track the people I despise, this slime will always be with us<br>
          Don’t look forward, don’t look back<br>
          The past is dead, the future black<br>
          Have no mercy, just attack<br>
          Or die with me<br>
          I am the Lord’s preyer <br>
          His ever-lovin’ demon slayer<br>
          My paranoia is perfect radio</p>

        <p>When I hear the word culture I reach for my gun <br>
          That’s why I live in a culture of one<br>
          Whoever you are wherever you’re from <br>
          When you say the word culture I reach for my gun <br>
          When I hear the word culture I reach for my gun<br>
          That’s why I live in a culture of one<br>
          Promise me promise me that from now on<br>
          When you hear the word culture you’ll reach for your gun</p>

        <p>My sworn role to stem the flood, even if it ends in blood<br>
          No surrender monkey, just a hate-filled junkie<br>
          Well you know just what it is, an endless hopeless deep abyss <br>
          They’ll find it pointless to resist, it’s the end of the line<br>
          Through my yellowed dreaming eyes, talk predation in disguise<br>
          Track the people I despise, the poor will always be with us<br>
          Well, as I live and breathe, to you my hatred I bequeath<br>
          The weakest left alone to grieve<br>
          My vacuous morality makes liars of us all </p>

        <p>When I hear the word culture I reach for my gun<br>
          That’s why I live in a culture of one<br>
          Whoever you are wherever you’re from<br>
          When you say the word culture I reach for my gun <br>
          When I hear the word culture I reach for my gun <br>
          That’s why I live in a culture of one <br>
          Promise me promise me that from now on <br>
          When you hear the word culture you’ll reach for your gun</p>

        <p>The tool of demagogues who sucker the masses,<br>
          manipulate their sorry asses <br>
          Those they claim to represent, fall victim to their vile intent <br>
          No surrender monkey, just a blood-lust junkie <br>
          The meek shall inherit nothing</p>


      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="death_to_the_nations_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#death_to_the_nations" aria-expanded="true" aria-controls="death_to_the_nations">
          Death to the Nations
        </a>
      </h5>
    </div>

    <div id="death_to_the_nations" class="collapse" aria-labelledby="death_to_the_nations_header" data-parent="#accordion">
      <div class="card-body">
        <p>Death to the nations <br>
          So get above your stations <br>
          Burn your flags, burn your flags<br>
          Burn your flags, burn your flags</p>

        <p>Death to the nations<br>
          So get above your stations <br>
          Burn your flags, burn your flags<br>
          Burn your flags, burn your flags</p>

        <p>Crack the idols, crack the idols<br>
          Tear them down, tear them down<br>
          Tear them down, tear them down<br>
          Break the past, regret the past <br>
          Aghast x 8</p>

        <p>Death to the nations <br>
          So get above your stations <br>
          Burn your flags, burn your flags<br>
          Burn your flags, burn your flags<br>
          Death to the nation state x 8</p>

        <p>Death to the nations <br>
          So get above your stations <br>
          Tear them down, tear them down <br>
          Break the past, regret the past <br>
          Aghast x 8</p>

      </div>
    </div>
  </div>
  <div class="card card-dark">
    <div class="card-header" id="hecatomb_header">
      <h5 class="mb-0">
        <a data-toggle="collapse" data-target="#hecatomb" aria-expanded="true" aria-controls="hecatomb">
          Hecatomb
        </a>
      </h5>
    </div>

    <div id="hecatomb" class="collapse" aria-labelledby="hecatomb_header" data-parent="#accordion">
      <div class="card-body">
        <p>Say we wanna live, we gotta try, even though no reason why <br>
          I guess the truth is that we care, to make sense of what is there <br>
          We cast around for higher ground, forever lost, but never found <br>
          Our every move, our every sound (cremate, soul full of hate)</p>

        <p>So we fly into the sun, in the shadow of a gun <br>
          Torn to pieces in mid-air, lie in hope, faced with despair <br>
          So we mouth our hopes and dreams, amid the piercing silent screams <br>
          As we crawl, we hit the wall (sacrifice, reach for the knives)  </p>

        <p>Hecatomb, hecatomb – all too soon <br>
          Hecatomb, hecatomb – meet your doom, now <br>
          Hecatomb, hecatomb – fall too soon<br>
          Hecatomb, hecatomb – from the womb, now  </p>

        <p>So we fly into the sun, in the shadow of the gun <br>
          Torn to pieces in mid-air, lie in hope, faced with despair<br>
          So we mouth our hopes and dreams, grandiose fantastic schemes <br>
          As we crawl down to the wall (sacrifice, reach for the knives)</p>

        <p>We wanna live, we gotta try, even though no reason why <br>
          I guess the truth is that we care, to make sense of what is there<br>
          We cast around for higher ground, forever lost, forever found<br>
          Our every move, our every sound (cremate, soul full of hate) </p>

        <p>Hecatomb, hecatomb – all too soon<br>
          Hecatomb, hecatomb – meet your doom, now<br>
          Hecatomb, hecatomb – fall too soon <br>
          Hecatomb, hecatomb – from the womb  </p>

        <p>Cut to pieces<br>
          It’s the sweetest <br>
          Scent and portent <br>
          Cremate, you’re so full of hate<br>
          Sacrifice, you reach for the knife (blown to pieces)</p>

        <p>Hunt like the animals, think like the cannibals <br>
          Distort reality, accept bestiality <br>
          Deny humanity, hang up morality</p>

        <p>Embrace mortality, lost in duality<br>
          immersed in depravity<br>
          forget spirituality<br>
          Ignore actuality<br>
          Indulge brutality</p>

        <p>As we fall down the years, wearing colours, bathed in tears<br>
          And the cities are aflame, and the people bowed in chains </p>

        <p>Politicians make crazy sounds, and all the dead bodies piled up in mounds<br>
          The blood gods they take their due, it’s the sacrifice of the weak and the true <br>
          Our kings and queens, the hand that’s played, they gaze across the world that they’ve made <br>
          But then you know we’re all to blame as the lifeless forests go up in flames</p>

        <p>So we falter down through the years, dressed in mourning, raining tears <br>
          And the cities burn again, and the children scream the pain that they’re in<br>
          And still the politicians make crazy sounds, and all the dead bodies piled up in mounds<br>
          And the blood prophets they pay their dues, it’s the sacrifice of the mes and the yous, yeah</p>

        <p>The sacrifice of the mes and the yous, yeah x 4 </p>

        <p>The seers are nowhere to be seen, an endless wail, keening without meaning<br>
          It’s something inside us all – not who we are but what we are<br>
          What held us together will tear us apart <br>
          What made us prevail will make us fail <br>
          This hunger for sacrifice, for lament, for death, for torment </p>

        <p>Hecatomb, hecatomb, hecatomb – all too soon, now <br>
          Hecatomb, hecatomb, hecatomb – greet your doom, now<br>
          Hecatomb, hecatomb, hecatomb – fall too soon, now<br>
          Hecatomb, hecatomb, hecatomb – bleeding from the womb</p>

      </div>
    </div>
  </div>
</div>
