import { Component, Inject, OnInit } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { Customer } from '../common/model/Customer';
import { Address } from '../common/model/Address';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  public customer: Customer;
  public address: Address;

  public addressForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl(),
    address: new FormGroup({
      line1: new FormControl('', [Validators.required]),
      line2: new FormControl(),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      postal_code: new FormControl('', [Validators.required]),
      state: new FormControl()
    })
  });

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.customer = this.storage.get('customer') || new Customer();
    this.address = this.storage.get('address') || new Address();

    this.addressForm.patchValue({
      name: this.customer.name,
      phone: this.customer.phone,
      address: this.address
    })
  }

  save() {
    if(this.addressForm.valid) {

      this.customer.name = this.addressForm.get('name').value;
      this.customer.phone = this.addressForm.get('phone').value;

      this.storage.set('customer', this.customer);
      this.storage.set('address', this.addressForm.get('address').value);
      this.router.navigate(['/checkout']);
    }
    else {
      this.validateAllFormFields(this.addressForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
