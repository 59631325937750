<div class="card card-dark">

<!--  <img class="card-img-top" src="/assets/images/downstairs.jpg" alt="Maxdmyz">-->

  <div class="card-body">

    <h1 class="card-title">About Maxdmyz</h1>

    <p class="card-text">Through the chapters of their colourful story to date Maxdmyz have played everywhere from the prestigious
      London venues like <span class="lead">The Underworld</span> and <span class="lead">The Dome</span> to the wild environs of <span class="lead">New York’s Torture Garden</span>. They
      have shared those stages with bands as diverse as <span class="lead">Tengger Cavalry</span> and <span class="lead">Ost Front</span>. There are no boundaries
      for Maxdmyz, they will play anywhere with anyone; either charming and hypnotising the audience or
      bludgeoning them into submission.</p>

    <p class="card-text">Building on their critically acclaimed Alchemical Metal EP, Anatomy Of Power is music to challenge your
      preconceptions. Music that asks questions and provokes responses. It’s music that needs to be heard and
      Maxdmyz are now seeking the right label to work with them and carry their message to the masses.</p>
  </div>

</div>
