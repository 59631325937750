<div class="d-flex px-2 py-5 justify-content-center align-items-center flex-column">

  <h1>Hecatomb Live at the Face Bar, Reading, 3rd of Sep 2021</h1>
  <p>Choose your version</p>

  <div class="d-flex justify-content-center align-items-center">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card card-dark mt-3">
          <div class="card-body">
            <h3 class="text-center">Full Song Version - 8min</h3>
            <a href="https://www.youtube.com/watch?v=_crBZ_278hE" (click)="getOutboundLink('https://www.youtube.com/watch?v=_crBZ_278hE')">
              <img class="card-img-top" src="https://img.youtube.com/vi/_crBZ_278hE/0.jpg" alt="Video">
            </a>
          </div>
        </div>

      </div>
      <div class="col-12 col-md-6">
        <div class="card card-dark mt-3">
          <div class="card-body">
            <h3 class="text-center">Shorter version - 3.5min</h3>
            <a href="https://www.youtube.com/watch?v=T5Ge5n05tKE" (click)="getOutboundLink('https://www.youtube.com/watch?v=T5Ge5n05tKE')">
              <img class="card-img-top" src="https://img.youtube.com/vi/T5Ge5n05tKE/0.jpg" alt="Video">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
