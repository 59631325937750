import { Component, Inject, OnInit } from '@angular/core';
import { Order } from '../common/model/Order';
import { Intent } from '../common/model/Intent';
import { HttpClient } from '@angular/common/http';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { Customer } from '../common/model/Customer';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  public total: number;
  private env;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private httpClient: HttpClient,
    private cartService: CartService,
    private router: Router,
  ) {

    this.env = (window.location.hostname === 'maxdmyz.uk' || window.location.hostname === 'maxdmyz.com') ? 'Prod' : 'Stage';
  }

  success() {
    document.getElementById('success').style.display = 'block';
    document.getElementById('terms').style.display = 'none';
    document.getElementById('back-to-checkout').style.display = 'none';
    document.getElementById('payment-request-button').style.display = 'none';

    let customer: Customer = this.storage.get('customer');

    if(customer.subscribe) {
      this.httpClient.post('https://qkm2t5z10i.execute-api.eu-west-2.amazonaws.com/' + this.env + '/subscribe', customer).subscribe();
    }

    this.storage.clear();
  }

  ngOnInit() {

    let order: Order = {
      items: this.storage.get('items'),
      address: this.storage.get('address'),
      customer: this.storage.get('customer')
    };

    if(order.items === undefined || order.items.length === 0) {
      this.router.navigate(['/'])
    }

    let parent = this;

    gtag('event', 'begin_checkout', {
      'items': order.items.map((item) => { return {id: item.sku, name: item.name, quantity: item.qty, price: item.price }} )
    });

    this.httpClient.post('https://qkm2t5z10i.execute-api.eu-west-2.amazonaws.com/' + this.env + '/intent', order).subscribe((intent: Intent) => {

      this.total = this.cartService.calculateTotal(order.items).total;

      const
        stripe = Stripe(intent.publishableKey),
        elements = stripe.elements(),
        paymentRequest = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: 'Album Preorder - Maxdmyz',
            amount: this.total * 100
          },
          requestPayerName: true,
          requestPayerEmail: true
        });

      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });

      document.getElementById('payment-request-button').style.display = 'block';

      paymentRequest.canMakePayment().then(function(result) {

        if (result) {
          document.getElementById('payment-form').style.display = 'none';
          prButton.mount('#payment-request-button');


          paymentRequest.on('paymentmethod', function(ev) {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            stripe.confirmCardPayment(
              intent.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            ).then(function(confirmResult) {
              if (confirmResult.error) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                const displayError = document.getElementById('pay-errors');
                displayError.textContent = confirmResult.error.message;
                displayError.style.display = 'block';
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');

                gtag('event', 'purchase', {
                  "transaction_id": confirmResult.paymentIntent.id,
                  "value": confirmResult.paymentIntent.amount_received,
                  "currency": "GBP",
                  "tax": 0,
                  "shipping": order.address.country !== 'United Kingdom' ? 5 : 0,
                  'items': order.items.map((item) => { return {id: item.sku, name: item.name, quantity: item.qty, price: item.price }} )
                });

                fbq('track', 'Purchase', {
                  value: confirmResult.paymentIntent.amount_received,
                  currency: 'GBP',
                  contents: order.items.map((item) => { return { id: item.sku, quantity: item.qty }} ),
                });

                parent.success();
              }
            });
          });

        } else {
          document.getElementById('payment-request-button').style.display = 'none';
          console.log('cannot make apple/google pay, fall back to normal card payment');

          const options = {
            style: {
              base: {
                color: "#f1f1f1",
                fontSize: '1.1em'
              }
            }
          };

          let card = elements.create("cardNumber", options);
          let expiry = elements.create("cardExpiry", options);
          let cvc = elements.create("cardCvc", options);

          card.mount("#card-element");
          expiry.mount("#expiry-element");
          cvc.mount("#cvc-element");

          document.getElementById('payment-form').style.display = 'block';

          card.addEventListener('change', ({error}) => {
            const displayError = document.getElementById('card-errors');
            if (error) {
              displayError.textContent = error.message;
            } else {
              displayError.textContent = '';
            }
          });

          const form = document.getElementById('payment-form');

          form.addEventListener('submit', function(ev) {
            ev.preventDefault();
            stripe.confirmCardPayment(intent.clientSecret, {
              payment_method: {
                card: card
              }
            }).then(function(result) {
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                document.getElementById('card-errors').textContent = result.error.message;
                document.getElementById('card-errors').style.display = 'block';
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                  form.style.display = 'none';

                  gtag('event', 'purchase', {
                    "transaction_id": result.paymentIntent.id,
                    "value": result.paymentIntent.amount_received,
                    "currency": "GBP",
                    "tax": 0,
                    "shipping": order.address.country !== 'United Kingdom' ? 5 : 0,
                    'items': order.items.map((item) => { return {id: item.sku, name: item.name, quantity: item.qty, price: item.price }} )
                  });

                  fbq('track', 'Purchase', {
                    value: result.paymentIntent.amount_received,
                    currency: 'GBP',
                    contents: order.items.map((item) => { return { id: item.sku, quantity: item.qty }} ),
                  });

                  parent.success();
                }
              }
            });
          });

        }
      });


    });
  }

}
