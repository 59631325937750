import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { MediaComponent } from './media/media.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShippingComponent } from './shipping/shipping.component';
import { SummaryComponent } from './summary/summary.component';
import { PayComponent } from './pay/pay.component';
import { TermsComponent } from './terms/terms.component';
import { PromoComponent } from './promo/promo.component';
import { PlayerComponent } from './player/player.component';
import { LyricsComponent } from './lyrics/lyrics.component';
import { SamplerComponent } from './sampler/sampler.component';
import { VideoComponent } from './video/video.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    NewsComponent,
    ContactComponent,
    MediaComponent,
    CatalogueComponent,
    CartComponent,
    CheckoutComponent,
    ShippingComponent,
    SummaryComponent,
    PayComponent,
    TermsComponent,
    PromoComponent,
    PlayerComponent,
    LyricsComponent,
    SamplerComponent,
    VideoComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
