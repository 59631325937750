<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">

    <a routerLink="/" class="float-right">Back to <i class="fas fa-home"></i></a>

    <h1>Checkout</h1>

    <ng-container *ngIf="items.length">

      <span class="float-right">Price</span><div class="clearfix"></div>
      <hr />
      <div class="media mt-5" *ngFor="let item of items">
        <img src="/assets/images/{{ item.sku }}.png" class="mr-3 col-3 d-none d-lg-block d-xl-block" alt="{{ item.name }}">
        <div class="media-body">
          <span class="float-right display-4">£{{ item.price }}</span>
          <h4 class="mt-2">{{ item.name }}</h4>
          <dl class="row">
            <dt class="col-3 p-0">Stock</dt>
            <dd class="col-9 p-0" *ngIf="item.sku == 'vinyl'">Available from July 2020, 200+ in stock.</dd>
            <dd class="col-9 p-0" *ngIf="item.sku == 'cd'">Available now, 400+ in stock.</dd>
            <dt class="col-3 p-0">Qty</dt>
            <dd class="col-9 p-0">
              <form class="form-inline">
                <div class="form-group mb-2 w-50">
                  <select class="form-control form-control-sm w-100" *ngIf="item.qty < 10" (change)="update(item, $event)">
                    <option *ngFor="let option of options" [value]="option.value" [selected]="option.value == item.qty">{{ option.label }}</option>
                  </select>
                  <input type="number" class="form-control form-control-sm w-100" [value]="item.qty" *ngIf="item.qty > 9" (change)="update(item, $event)"/>
                </div>
                <div class="form-group">
                  <span class="ml-3 text-danger cursor-pointer" (click)="delete(item)">Delete</span>
                </div>
              </form>
            </dd>
          </dl>
        </div>
      </div>
      <hr>
      <span class="float-right">Postage and packaging: <span>£{{ calcs.total - calcs.subtotal }}</span></span><br><br>
      <span class="float-right">Subtotal ({{ calcs.count}} items): <span class="display-4">£{{ calcs.total }}</span></span>

    </ng-container>

    <div *ngIf="items.length === 0" class="empty-space">
      <h1 class="text-center"><i class="fas fa-shopping-basket text-center mt-5"></i></h1>
      <p class="text-center m-5">Your basket is empty. Please add something to proceed.</p>
    </div>

    <div class="clearfix">
      <a class="btn btn-warning mt-4 mr-2" (click)="add('vinyl')"><i class="fas fa-cart-plus"></i> Add Vinyl £25</a>
      <a class="btn btn-warning mt-4 mr-2" (click)="add('cd')"><i class="fas fa-cart-plus"></i> Add CD £15</a>
    </div>

  </div>
</div>

<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">
    <h4>Shipping Address</h4>

    <p>
      <strong>{{ customer.name }}</strong><br>
      <span>{{ address.line1 }}</span><br>
      <span *ngIf="address.line2">{{ address.line2 }}<br></span>
      <span *ngIf="address.city">{{ address.city }}<br></span>
      <span *ngIf="address.country">{{ address.country }}<br></span>
      <span *ngIf="address.postal_code">{{ address.postal_code }}<br></span>
      <span *ngIf="address.state">{{ address.state }}<br></span>
      <span *ngIf="customer.phone">tel. {{ customer.phone }}<br></span>
    </p>

    <div *ngIf=" ! address.country" class="empty-space">
      <h1 class="text-center"><i class="fas fa-shipping-fast text-center mt-5"></i></h1>
      <p class="text-center m-5">Missing shipping address. Please add your delivery address to proceed.</p>
    </div>

    <a routerLink="/shipping" class="btn btn-warning mt-4 mr-2">Add/Edit address</a>
  </div>
</div>

<div class="card card-dark mt-3 mt-md-5">
  <div class="card-body">
    <h4>Confirmation</h4>
    <p>Confirmation for the order will be sent to the email address below.</p>
    <form [formGroup]="emailForm" (change)="submit()">
      <input type="email" class="form-control form-control-lg w-100" [class.is-invalid]="emailForm.get('emailAddress').invalid && emailForm.get('emailAddress').touched" formControlName="emailAddress" />
      <ng-container *ngIf="(emailForm.get('emailAddress').invalid && emailForm.get('emailAddress').touched) || emailForm.get('emailAddress').dirty">
        <small *ngIf="emailForm.get('emailAddress').errors?.required" class="text-danger">Email address is required</small>
        <small *ngIf="emailForm.get('emailAddress').errors?.pattern" class="text-danger">Please provide a valid email address</small>
      </ng-container>
      <small class="form-text text-muted">We will not use your email address for any marketing purposes, unless you tick the checkbox below.</small>
      <label for="subscribe"><input type="checkbox" class="mt-3" formControlName="subscribe" id="subscribe"/> Subscribe to mailing list</label>
    </form>
  </div>
</div>
