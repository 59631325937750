import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { MediaComponent } from './media/media.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShippingComponent } from './shipping/shipping.component';
import { PayComponent } from './pay/pay.component';
import { TermsComponent } from './terms/terms.component';
import { PromoComponent } from './promo/promo.component';
import { LyricsComponent } from './lyrics/lyrics.component';
import { StreamComponent } from './stream/stream.component';
import { VideoComponent } from "./video/video.component";


const routes: Routes = [
  { path: 'checkout', component: CheckoutComponent },
  { path: 'catalogue', component: CatalogueComponent },
  { path: 'media', component: MediaComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'news', component: NewsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'stream', component: StreamComponent },
  { path: 'hecatomb-live-at-the-face-bar-reading-sep-2021', component: VideoComponent },
  { path: 'shipping', component: ShippingComponent },
  { path: 'pay', component: PayComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'hidden-promo-page.html', component: PromoComponent },
  { path: 'hidden-album-preview-with-lyrics.html', component: LyricsComponent },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
