import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Maxdmyz';

  private subscription: Subscription;

  current: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if ( event.url !== undefined ) {
          if ( event.url.indexOf('?') !== -1 ) {
            this.current = event.urlAfterRedirects.substring(0, event.url.indexOf('?'));
          } else {
            this.current = event.urlAfterRedirects;
          }

          gtag('config', 'UA-106099707-1', {
            page_path: event.urlAfterRedirects
          });
          fbq('track', 'PageView');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isFullSreen() {
    return ['/', '/hecatomb-live-at-the-face-bar-reading-sep-2021'].includes(this.current);
  }
}
