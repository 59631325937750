import { Component, OnInit } from '@angular/core';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html'
})
export class VideoComponent {

  getOutboundLink(url) {
    gtag('event', 'click', {
      event_category: 'outbound',
      event_label: url,
      transport_type: 'beacon'
    });
    fbq('trackCustom', 'OutboundClick', { url: url });
  }
}
