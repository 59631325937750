import { Component } from '@angular/core';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-home',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent {

  constructor() { }

  getOutboundLink(url) {
    gtag('event', 'click', {
      'event_category': 'outbound',
      'event_label': url,
      'transport_type': 'beacon'
    });
    fbq('trackCustom', 'OutboundClick', { url: url });
  }
}
