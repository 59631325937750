<div class="card card-dark">
        <img class="card-img-top" src="/assets/img/anatomy.jpg" alt="Maxdmyz">
        <div class="card-body">
          <h1 class="card-title">Anatomy of Power</h1>

          <blockquote class="blockquote">
            <p class="mb-0">What has been will be again, what has been done will be done again; there is nothing new under the
              sun.</p>
            <footer class="blockquote-footer">Ecclesiastes 1:9</footer>
          </blockquote>

          <p class="card-text text-justify">Those words, ‘there is nothing new under the sun’ have passed into common parlance over the centuries,
            but Maxdmyz have spent their career disproving that maxim. Using familiar tools and familiar materials
            they have constructed sonic edifices that startle and surprise, aural architecture that is recognisable
            in its parts, but surprising, fresh and exciting. Their new album, <span class="lead">Anatomy Of Power</span> has been two years
            in the making. Recorded at <span class="lead">The Parlour Studios</span> (Dimmu Borgir, Fields Of The Nephilim, Evile etc) under
            the guidance of producer Neil Haynes (XII Boar, Sacred Mother Tongue, The Curious Incident etc) and
            mastered at <span class="lead">Hertz Studios</span> (Behemoth, Decapitated, Vader etc.), Anatomy Of Power sounds superb, the
            incredible songs crackling with energy and vitality. Be it the epic ‘My State’, with its seismic shifts
            from furious velocity to introspective melancholy, the pulsing, rhythmic belligerence of ‘Culture Of
            One’, with its infectious chorus, or the haunting album closer, ‘Hecatomb’ – every song on Anatomy Of
            Power is alive, unique and unforgettable. Extreme metal, that draws on the industrial, the progressive
            and the fiercely independent, that isn’t afraid to look back to the principles of great rock music and
            forward to new horizons and possibilities. Maxdmyz blend visions of a dystopian future with ancient,
            arcane knowledge, a blazing rage against the mechanisation of humanity with a deeply felt appreciation
            for the remnants of our finer emotions. Science and magic, love and hate all collide and react to create
            their special alchemy and all their many elements have been captured by <span class="lead">Dan Goldsworthy</span> (Accept,
            Alestorm, Hell etc) in his stunning cover art. Possibly the finest work that this highly respected
            artist has ever created, it sets the seal on this remarkable album. To accompany the album Maxdmyz were
            shot by world renowned photographer <span class="lead">Tina Korhonen</span> (Kiss, Arch Enemy, Slayer and countless others), who
            beautifully portrayed the disparate characters who have come together in this, the strongest ever
            Maxdmyz line-up.</p>
        </div>
      </div>

<br>

<div class="card card-dark">
        <img class="card-img-top" src="/assets/img/downstairs.jpg" alt="Maxdmyz">
        <div class="card-body">
          <h1 class="card-title">About Maxdmyz</h1>

          <p class="card-text">Through the chapters of their colourful story to date Maxdmyz have played everywhere from the prestigious
            London venues like <span class="lead">The Underworld</span> and <span class="lead">The Dome</span> to the wild environs of <span class="lead">New York’s Torture Garden</span>. They
            have shared those stages with bands as diverse as <span class="lead">Tengger Cavalry</span> and <span class="lead">Ost Front</span>. There are no boundaries
            for Maxdmyz, they will play anywhere with anyone; either charming and hypnotising the audience or
            bludgeoning them into submission.</p>

          <p class="card-text">Building on their critically acclaimed Alchemical Metal EP, Anatomy Of Power is music to challenge your
            preconceptions. Music that asks questions and provokes responses. It’s music that needs to be heard and
            Maxdmyz are now seeking the right label to work with them and carry their message to the masses.</p>

          <p class="card-text lead">MAXDMYZ are driven, motivated, focussed and hard working. They are talented musicians with the will to
            succeed and would make the perfect addition to any roster.</p>
        </div>
    </div>

