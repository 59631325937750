<div class="container h-100">
  <div class="row">
    <div [ngClass]="{'col-md-12': isFullSreen(), 'col-md-8': ! isFullSreen()}">
      <router-outlet></router-outlet>
    </div>

    <div class="col-md-4" [ngSwitch]="current">
      <app-summary *ngSwitchCase="'/checkout'" [route]="current"></app-summary>
      <app-summary *ngSwitchCase="'/pay'" [route]="current"></app-summary>
      <app-summary *ngSwitchCase="'/terms'" [route]="current"></app-summary>
    </div>
  </div>
</div>


