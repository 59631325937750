import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Item } from '../common/model/Item';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Address } from '../common/model/Address';
import { HttpClient } from '@angular/common/http';
import { Order } from '../common/model/Order';

import { Intent } from '../common/model/Intent';
import { Customer } from '../common/model/Customer';
import { CartService } from '../cart.service';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  public items: Array<Item>;
  public address: Address = new Address();
  public customer: Customer = new Customer();
  public calcs: { total: number, subtotal: number, count: number };
  public options: Array<{ value: number, label: string }> = [
    { value: 0, label: "0 (Delete)"},
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10+" }
  ];

  private subscriptions: Array<Subscription> = [];

  emailForm = new FormGroup(
    {
      emailAddress: new FormControl('', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$') ]),
      subscribe: new FormControl('')
    });

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cartService: CartService,
    private userService: UserService,
    private httpClient: HttpClient
  ) {}


  ngOnInit() {

    this.items = this.storage.get('items') || [];
    this.address = this.storage.get('address') || new Address();
    this.customer = this.storage.get('customer') || new Customer();
    this.calcs = this.cartService.calculateTotal(this.items);

    this.emailForm.patchValue({
      emailAddress: this.customer.email,
      subscribe: this.customer.subscribe
    });

    this.subscriptions.push(this.cartService.changeEmitted$.subscribe((items: Array<Item>) => {
      this.items = items;
      this.calcs = this.cartService.calculateTotal(items);
    }));

    this.subscriptions.push(this.userService.changeEmitted$.subscribe((customer: Customer) => {
      this.customer = customer;
    }));
  }

  pay() {
    console.log('get intent');

    let order: Order = {
      items: this.storage.get('items'),
      address: this.storage.get('address'),
      customer: this.storage.get('customer')
    };

    this.httpClient.post('https://qkm2t5z10i.execute-api.eu-west-2.amazonaws.com/Prod/intent', order).subscribe((intent: Intent) => {
      console.log(intent);

      let stripe = Stripe(intent.publishableKey);

      var elements = stripe.elements();
      var style = {
        base: {
          color: "#c1c1c1",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      var card = elements.create("card", { style: style });
      card.mount("#card-element");

      var form = document.getElementById("payment-form");
      form.addEventListener("submit", function(event) {
        event.preventDefault();
        // Initiate payment when the submit button is clicked
        stripe
          .confirmCardPayment(intent.clientSecret, {
            payment_method: {
              card: card
            }
          })
          .then(function(result) {
            console.log(result);
          });
      });

    });
  }

  delete(item: Item) {
    this.cartService.remove(item);
  }

  update(item: Item, $event: Event) {
    const qty: number = +(<HTMLInputElement>$event.target).value;
    if(qty > 0) {
      this.cartService.setQty(item, qty);
    }
    else {
      this.cartService.remove(item);
    }
  }

  add(sku: string, qty: number = 1) {
    this.cartService.add(sku, qty);
  }

  submit() {
    if( ! this.emailForm.invalid) {
      this.userService.setEmail(this.emailForm.getRawValue());
    }
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
