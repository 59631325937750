import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private cartService: CartService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  add(sku: string, qty: number = 1) {
    this.cartService.add(sku, qty);
    this.router.navigate(['/checkout']);
  }

  getOutboundLink(url) {
    gtag('event', 'click', {
      'event_category': 'outbound',
      'event_label': url,
      'transport_type': 'beacon'
    });
    fbq('trackCustom', 'OutboundClick', { url: url });
  }
}
