import { Component, Inject, OnInit } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CartService } from '../cart.service';
import { Item } from '../common/model/Item';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public items: Array<Item>;
  public calcs: { total: number, subtotal: number, count: number };

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.items = this.storage.get('items') || [];
    this.calcs = this.cartService.calculateTotal(this.items);
    this.cartService.changeEmitted$.subscribe((items: Array<Item>) => {
      this.items = items;
      this.calcs = this.cartService.calculateTotal(items);
    });
  }
}
