import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Item } from './common/model/Item';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Address } from './common/model/Address';

declare let gtag: Function;
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private emitChange = new Subject<any>();

  // this needs to come from API
  private _products: Array<Item> = [
    {name: 'Anatomy of Power CD', price: 15, sku: 'cd'},
    {name: 'Anatomy of Power Vinyl', price: 25, sku: 'vinyl'}
  ];

  changeEmitted$ = this.emitChange.asObservable();
  private countries: Array<string> = [
    'United Kingdom'
  ];

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  get products(): Array<Item> {
    return this._products;
  }

  add(sku: string, qty: number = 1) {

    for(let product of this._products) {

      if(product.sku === sku) {

        const items: Array<Item> = this.storage.get('items') || [];

        for (let candidate of items) {
           if(candidate.sku == sku) {
             candidate.qty += qty;
             this.storage.set('items', items);

             gtag('event', 'add_to_cart', {
               "items": [
                 {
                   "id": sku,
                   "name": product.name,
                   "quantity": qty,
                   "price": product.price
                 }
               ]
             });

             fbq('track', 'AddToCart', {
               value: product.price * qty,
               currency: 'GBP',
               contents: [
                 {
                   id: sku,
                   quantity: qty
                 }
               ],
             });

             return this.emitChange.next(items);
           }
        }

        product.qty = qty;
        items.push(product);
        this.storage.set('items', items);

        gtag('event', 'add_to_cart', {
          "items": [
            {
              "id": sku,
              "name": product.name,
              "quantity": qty,
              "price": product.price
            }
          ]
        });

        fbq('track', 'AddToCart', {
          value: product.price * qty,
          currency: 'GBP',
          contents: [
            {
              id: sku,
              quantity: qty
            }
          ],
        });

        return this.emitChange.next(items);
      }
    }
  }

  remove(item: Item) {

    const items: Array<Item> = this.storage.get('items') || [];

    for (let candidate of items) {
      if(candidate.sku == item.sku) {
        items.splice(items.indexOf(candidate, 0), 1);
        this.storage.set('items', items);

        gtag('event', 'remove_from_cart', {
          "items": [
            {
              "id": item.sku,
              "name": candidate.name,
              "quantity": candidate.qty,
              "price": candidate.price
            }
          ]
        });

        return this.emitChange.next(items);
      }
    }
  }

  setQty(item: Item, qty: number) {

    const items: Array<Item> = this.storage.get('items') || [];

    for (let candidate of items) {
      if(candidate.sku == item.sku) {
        candidate.qty = qty;
        this.storage.set('items', items);

        gtag('event', 'set_product_qty', {
          'event_category': 'commerce',
          'event_label': item.sku,
          'value': qty
        });

        return this.emitChange.next(items);
      }
    }
  }

  public calculateTotal(items): any {
    let subtotal = 0, total = 0, count = 0;

    let address: Address = this.storage.get('address');

    for(let item of items) {
      subtotal += item.qty * item.price;
      count += item.qty;
    }


    if (address && this.countries.indexOf(address.country) === -1) {
      total = (subtotal > 0) ? subtotal + 5 : subtotal;
    }
    else {
      total = subtotal;
    }


    return { subtotal: subtotal, total: total, count: count };
  }
}
